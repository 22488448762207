<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>PLANES</h3>
            | <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA DE PLANES
          </v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$can('handle_planning')"
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'planning.createPlan',
            }"
          >
            Nuevo Plan
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-layout row wrap class="mx-2 mb-5">
        <v-flex xs12 md2 class="ml-3 mt-5">
            <v-autocomplete
                :items="[
                      { name: 'Todos', id: null },
                      ...allPlanningReference?.mandate,
                    ]"
                item-text="name"
                item-value="id"
                single
                label="Nivel"
                v-model="selectedMandate"
            ></v-autocomplete>
        </v-flex>
          <v-flex xs12 md2 class="ml-3 mt-5">
            <v-autocomplete
                :items="['Todos', ...allPlanningReference?.years]"
                single
                label="Año"
                v-model="selectedYear"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 md2 class="ml-3 mt-5">
            <v-autocomplete
                :items="[
                      { name: 'Todos', id: null },
                      ...allPlanningReference?.categories,
                    ]"
                item-text="name"
                item-value="id"
                single
                label="Categoría"
                v-model="selectedCategory"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 md2 class="ml-3 mt-5">
            <v-autocomplete
                :items="[
                      { descripcion: 'Todos', territorio_id: null },
                      ...allDatasource?.data?.Territorio,
                    ]"
                item-text="descripcion"
                item-value="territorio_id"
                single
                label="Ubicación"
                v-model="selectedTerritory"
            ></v-autocomplete>
          </v-flex>
        <v-flex xs12 md2 class="ml-3 mt-5">
          <v-text-field
              v-model="search"
              label="Buscar"
              single-line
              hide-details
          ></v-text-field>
        </v-flex>
          <v-flex xs12 md1 class="ml-3 mt-5">
            <v-btn
                rounded
                outlined
                color="primary"
                dark
                class="mt-2"
                @click="getAndFilterPlans"
            >
              Filtrar
            </v-btn>
          </v-flex>
      </v-layout>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="plans"
          item-key="id"
        >
          <template v-slot:item.categories="{ item }">
            <v-chip
              v-for="(category, index) in item.categories"
              color="#D3E9D7"
              :key="index"
              style="height: auto; margin-top: 11px; margin-left: 5px"
            >
              <span style="color: #222222; font-size: 12px">{{
                category.name
              }}</span>
            </v-chip>
          </template>
          <template v-slot:item.tracking="{ item }">
            <span>
              {{
                !item.actions[0]?.tracking?.length
                  ? "0"
                  : item.actions[0]?.tracking?.length
              }}/4
              <v-tooltip
                bottom
                content-class="custom-tooltip"
                class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#1FB2E1"
                    class="ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <p
                  style="
                    font-size: 14px;
                    padding-left: 14px;
                    padding-right: 14px;
                  "
                >
                  Los seguimientos a las acciones del plan se realizan
                  anualmente durante 4 años. <br />
                  El próximo seguimiento a este plan es el:
                </p>
                <p class="text-center text-md-h6">
                  {{
                    item.actions[0]?.tracking[0]?.next_date_tracking ||
                    item.first_date_tracking
                  }}
                </p>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :key="item.id" style="height: 50px; width: 60px">
                  <v-list-item-title v-if="$can('handle_planning')"  @click="showPlan(item)">
                    Ver
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { listPlansResponse } from "@/services/Planning";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "ListOfCases.vue",
  data() {
    return {
      plans: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "start_date" },
        { text: "CATEGORIÁ (S)", value: "categories" },
        { text: "TERRITORIO", value: "territory.descripcion" },
        { text: "NIVEL", value: "mandate.name" },
        { text: "TITULO", value: "name" },
        { text: "MANDATO", value: "description" },
        { text: "AÑO MANDATO", value: "mandate_year" },
        { text: "SEGUIMIENTO", value: "tracking" },
        { text: "ACCIONES", value: "action", sortable: false },
      ],
      selectedMandate: null,
      selectedYear: null,
      selectedCategory: null,
      selectedTerritory: null,
    };
  },
  methods: {
    showPlan(plan) {
      this.$router.push({
        name: "planning.tracking",
        params: { planId: plan.id },
      });
    },
    async getAndFilterPlans() {
      try {
        const response = await listPlansResponse({
          filters: {
            mandate: this.selectedMandate,
            ...(this.selectedYear !== "Todos" && { year: this.selectedYear }),
            category: this.selectedCategory,
            territory: this.selectedTerritory,
            search: this.search,
          },
        });
        this.plans = response.data;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salió mal!",
        });
      }
    },
  },
  async created() {
    try {
      this.showLoader();
      this.plans = (await listPlansResponse())?.data;
      this.hideLoader();
    } catch (err) {
      console.error(err);
      Swal.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  computed: {
    ...mapGetters(["allDatasource", "allPlanningReference"]),
  },
};
</script>

<style>
.custom-tooltip {
  background-color: #1fb2e1;
  color: white;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}
</style>
